import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InternalSubjectService {
    private subject = new Subject<any>();
    setSubject(status: any, message: any) {
        this.subject.next({ status: status, data: JSON.parse(message) || '' });
    }

    clearSubject() {
        this.subject.next();
    }

    getSubject(): Observable<any> {
        return this.subject.asObservable();
    }
}
