import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  constructor() {
  }
  ngOnInit() {
    const demoWorker = new Worker('./app.worker', { type: 'module' });
    demoWorker.onmessage = (message) => {
      console.log(message)
      console.log(`Got message`, message.data);
    };
    // demoWorker.postMessage('test 1234')
  }

}
