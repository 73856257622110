import { Injectable, OnInit } from '@angular/core';
import { environment } from '../../environments/environment'
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http'
import { AuthService } from './auth.service'
import { RequestHandlerService } from './request-handler.service'
// import { map, catchError } from 'rxjs/internal/operators';


@Injectable({ providedIn: 'root' })


export class CovidAdminService {
    header: HttpHeaders
    adminURL = ''
    qrcode_expire = ''
    constructor(private http: RequestHandlerService, private auth: AuthService) {
        this.adminURL = environment.environment.adminURL
        this.qrcode_expire = environment.environment.qrcode_expire
    }

    getToken(hcode, exp) {
        let exp_minutes = ''
        if (exp != "") {
            exp_minutes = exp // custom time
        } else {
            exp_minutes = this.qrcode_expire // default by env
        }
        let url = `${this.adminURL}/qrcode/${exp_minutes}`
        if (hcode != '')
            url = url + `/${hcode}`
        return this.http.get(url)
    }
}
