import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service'

@Injectable({
    providedIn: 'root'
})
export class RoleGuardService implements CanActivate {

    sideBarActive

    constructor(public router: Router, private auth: AuthService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        // if (this.auth.getUserRole() === 'admin') {
        //     return true
        // } else {
        //     return false
        // }

        return true;
    }
}
