import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { of, race, BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment';
// import { CookieService } from 'ngx-cookie-service';
import { InternalSubjectService } from './internal-subject.service'
import { SharedWorkerService } from '../services/shared-worker.service'
import { OAuthService, NullValidationHandler, OAuthEvent } from 'angular-oauth2-oidc';
import { authConfig } from '../auth.config';
import { filter, delay } from 'rxjs/operators';
import * as jwt_decode from 'jwt-decode';

@Injectable()
export class AuthService {
  expiredDate: Date = new Date()
  public userSubject: BehaviorSubject<any>
  public currentUser: Observable<any>;
  nameSpacedRole = environment.environment.nameSpacedRole
  key_payload = environment.environment.key_payload

  public cliams
  constructor(
    public router: Router,
    private internalSubject: InternalSubjectService,
    private sharedWorker: SharedWorkerService,
    private http: HttpClient,
    private oauthService: OAuthService
  ) {
    // this.userSubject = new BehaviorSubject<any>(localStorage.getItem('claim'));
    this.configureWithNewConfigApi();
  }


  login() {
    this.oauthService.initImplicitFlow('/some-state;p1=1;p2=2');

    if (this.hasValidIdToken()) {
      // this.sharedWorker.startSharedWorker()
    }
    // the parameter here is optional. It's passed around and can be used after logging in
  }

  logout() {
    this.oauthService.logOut();
    localStorage.clear();
    // this.sharedWorker.sharedWorker.port.close()
  }

  public hasValidIdToken() {
    return this.oauthService.hasValidIdToken();
  }

  public requestAccessToken() {
    return this.oauthService.requestAccessToken;
  }

  public id_token() {
    return this.oauthService.getIdToken();
  }

  public access_token() {
    return this.oauthService.getAccessToken();
  }

  public id_token_expiration() {
    return this.oauthService.getIdTokenExpiration();
  }

  public access_token_expiration() {
    return this.oauthService.getAccessTokenExpiration();
  }

  // public getClaims() {
  //   const cliams = this.oauthService.getIdentityClaims();
  //   return cliams;
  // }
  public getClaims() {
    // if(this.cliams==""){
    // console.log(this.access_token())
    // const cliams = JSON.parse(atob(this.access_token().split(".")[1]))
    // const cliams = this.parseJwt(this.access_token())
    const cliams = jwt_decode(this.access_token().split(".")[1], { header: true })
    // console.log(cliams)
    return cliams
    // }
    // return this.cliams;
  }

  public scopes() {
    const scopes = this.oauthService.getGrantedScopes();
    return scopes;
  }
  public getUserRole() {
    // const role = this.getClaims()[this.nameSpacedRole][0]
    // return role
    return 'admin'
  }

  public getRole() {
    return this.getClaims()[this.key_payload]['role']
  }

  public getPrefix() {
    return this.getClaims()[this.key_payload]['prx']
  }

  public getAccessLevel() {
    console.log(this.getClaims()['claims']['access_level'])
    return this.getClaims()['claims']['access_level']
  }

  // This api will come in the next version
  private configureWithNewConfigApi() {
    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();


    // Optional
    this.oauthService.setupAutomaticSilentRefresh();

    this.oauthService.events.subscribe(e => {
      // tslint:disable-next-line:no-console
      console.debug('oauth/oidc event', e);
    });

    this.oauthService.events
      .pipe(filter(e => e.type === 'session_terminated'))
      .subscribe(e => {
        // tslint:disable-next-line:no-console
        console.debug('Your session has been terminated!');
      });

    this.oauthService.events
      .pipe(filter(e => e.type === 'token_received'))
      .subscribe(e => {
        // console.log('token_received');
        window.location.href = '/qrcode';
      });
  }

  public get currentUserValue(): any {
    return this.userSubject.value;
  }



  public hasValidIdToken2() {
    return true
  }

}
