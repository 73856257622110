import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthGuardService } from './services/auth-guard.service'
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component'
import { RoleGuardService } from './services/role-guard.service'

export const AppRoutes: Routes = [{
    path: '',
    redirectTo: 'qrcode',
    pathMatch: 'full',
},
{
    path: 'callback',
    children: [
        {
            path: '',
            loadChildren: './callback/callback.module#CallbackModule',
        }
    ]
},
{
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
        {
            path: 'qrcode',
            loadChildren: './qrcode/qrcode.module#QrcodeModule'
        },
    ]
}, {
    path: '',
    component: AuthLayoutComponent,
    children: [{
        path: '',
        loadChildren: './pages/pages.module#PagesModule'
    }]
}, {
    path: '**', redirectTo: '/qrcode'
}
];
