import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, Observable, from } from 'rxjs'
import { keyframes } from '@angular/animations';
declare const $: any;
import { environment } from '../../environments/environment';
import { Notify } from './interface.service';
import { NotificationService } from './notification.service'
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class SharedWorkerService {
  sharedWorker: SharedWorker.SharedWorker;
  notifyData: Notify

  constructor(private notification: NotificationService) {
  }

  startSharedWorker () {
    if (localStorage.getItem('claim')) {
      this.connectSharedWorker()
      this.getDataWorker()
    }
  }

  connectSharedWorker() {
    this.sharedWorker = new SharedWorker('../../assets/sharedworker/shared-worker.worker.js');
    this.sharedWorker.onerror = (event) => {
      console.error(event)
      this.sharedWorker.port.start();
    }
  }

  getDataWorker() {
    this.sharedWorker.port.onmessage = ({ data }) => {
      // console.log(data)
      const SSEData = JSON.parse(data)
      // console.log(SSEData['timestamp'])
      // if (SSEData['timestamp'] % 5 === 0) {
      //   this.boardcastNotify(SSEData.message)
      // }
    };
  }

  getWorkerStatus () {
    if (this.sharedWorker === undefined) {
      return false;
    } else {
      return true;
    }
  }

  boardcastNotify(data) {
    this.notifyData = {
      icon: 'nc-ambulance',
      type: 'danger',
      message: `Suspected COVID-19! ${data}`
    }
    this.notification.showNotification(this.notifyData)
  }
}
