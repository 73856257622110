import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit } from '@angular/core';
import { Location } from '@angular/common';
import { SharedWorkerService } from '../services/shared-worker.service'
import { Router } from '@angular/router';
import { RoleGuardService } from '../services/role-guard.service'
import { AuthService } from '../services/auth.service'

// Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    role: Array<string>
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    path: string;
    title: string;
    ab: string;
    type?: string;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
    {
        path: '/qrcode',
        title: 'QR Code ลงทะเบียน',
        type: 'link',
        role: ['admin', 'user'],
        icontype: 'nc-icon nc-credit-card'
    },
    {
        path: '/logout',
        title: 'Logout',
        type: 'link',
        role: ['admin', 'user'],
        icontype: 'nc-icon nc-button-power',
    }

];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    private permission = ''
    userData = {}
    username = ''
    constructor(private shareWorker: SharedWorkerService, private localtion: Location, private auth: AuthService) {
    }

    isNotMobileMenu() {
        if (window.outerWidth > 991) {
            return false;
        }
        return true;
    }

    ngOnInit() {
        // if (!this.shareWorker.getWorkerStatus() && this.localtion.path() !== '/login') {
        //     this.shareWorker.startSharedWorker()
        // }
        this.permission = this.auth.getUserRole()
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.userData = this.auth.getClaims()
        this.username = this.userData["claims"]["profile"]["username"]
    }

    checkShowSideBar(menu) {
        return menu.role.includes(this.permission)
    }
}
