import { Injectable, OnInit } from '@angular/core';
import { Notify } from './interface.service'
declare const $: any;






@Injectable({ providedIn: 'root' })


export class NotificationService {
    dangerData: Notify

    constructor() {
    }

    showNotification(dataNotify: Notify) {
        const currObj = this
        // const type = ['', 'info', 'success', 'warning', 'danger'];

        // const color = Math.floor((Math.random() * 4) + 1);
        $.notify({
            icon: dataNotify.icon,
            message: dataNotify.message,
            url: "http://localhost:4200/viewDetails/12",
            target: "_blank",
        }, {
            type: dataNotify.type,
            timer: 1000,
            onClose: function () {
                console.log('close popup')
            },
            placement: {
                from: 'bottom',
                align: 'right'
            },
            onClick: function (evt) {
                alert('Do not touch me!');
            },
            template: `<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert"><button type="button"
                aria-hidden="true" class="close" data-notify="dismiss"><i class="nc-icon nc-simple-remove"></i></button><span
                data-notify="icon" class="nc-icon"></span> <span data-notify="title">{1}</span> <span
                data-notify="message">{2}</span>
                <div class="progress" data-notify="progressbar">
                    <div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0"
                    aria-valuemax="100" style="width: 0%;"></div>
                    </div><a href="{3}" target="{4}" data-notify="url"></a>
                </div>`
        })
    }

}
