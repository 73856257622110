import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';

import { SidebarModule } from './sidebar/sidebar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AppRoutes } from './app.routing';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service'
import { SharedWorkerService } from './services/shared-worker.service'
import { from } from 'rxjs';
import { AppInitService } from './app.init';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CovidAdminService } from './services/covid-admin.service'
import { RoleGuardService } from './services/role-guard.service';
import { RequestHandlerService } from './services/request-handler.service'
import { QRCodeModule } from 'angularx-qrcode';
import { NgxPrintModule } from 'ngx-print';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

export function init_app(appLoadService: AppInitService) {
    return () => appLoadService.init();
}
@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        RouterModule.forRoot(AppRoutes, {
            useHash: false
        }),
        NgbModule,
        OAuthModule.forRoot(),
        SidebarModule,
        NavbarModule,
        FooterModule,
        FixedPluginModule,
        HttpClientModule,
        QRCodeModule,
        NgxPrintModule,
        AutocompleteLibModule
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthGuardService,
        AuthService,
        SharedWorkerService,
        CovidAdminService,
        AppInitService,
        {
            provide: APP_INITIALIZER,
            useFactory: init_app,
            deps: [AppInitService],
            multi: true
        },
        RoleGuardService,
        RequestHandlerService,
    ]
})

export class AppModule { }
