import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service'

@Injectable()
export class AuthGuardService {

  constructor(public router: Router, private auth: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log(this.auth.id_token())
    // console.log(this.auth.getUserRole())
    if (this.auth.access_token()) {
      return true;
    } else {
      this.router.navigate(['/login'])
      return false;
    }
  }
}
