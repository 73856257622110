import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest } from '@angular/common/http'
import { AuthService } from './auth.service'
import { map, catchError } from 'rxjs/internal/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router'


@Injectable({
  providedIn: 'root'
})
export class RequestHandlerService {

  header: HttpHeaders
  adminURL = ''
  constructor(private http: HttpClient, private auth: AuthService, private route: Router) {
    this.header = (new HttpHeaders()).set('Authorization', `Bearer ${this.auth.id_token()}`)
  }


  get(url) {
    return this.http.get(url, { headers: this.header }).pipe(map((res) => {
      return res
    }), catchError(err => {
      if (err.status == 401) {
        this.route.navigate(['logout'])
      }
      return throwError(err);
    }))
  }

  postRequestFormData(url, body: FormData, header) {
    if (header) {
      this.header.set(Object.keys(header)[0], header[Object.keys(header)[0]])
    }
    return this.http.request(new HttpRequest<FormData>('POST', url, body, { 'headers': this.header })).pipe(map((res) => {
      return res
    }), catchError(err => {
      if (err.status == 401) {
        this.route.navigate(['logout'])
      }
      return throwError(err);
    }))
  }

  putRequestFormData(url, body: FormData, header) {
    if (header) {
      this.header.set(Object.keys(header)[0], header[Object.keys(header)[0]])
    }
    return this.http.request(new HttpRequest<FormData>('PUT', url, body, { 'headers': this.header })).pipe(map((res) => {
      return res
    }), catchError(err => {
      if (err.status == 401) {
        this.route.navigate(['logout'])
      }
      return throwError(err);
    }))
  }

}
